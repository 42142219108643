<template>
  <div>
    <el-form :inline="true">
      <!-- 添加/搜索区域 -->
      <el-form-item>
        <el-button @click="addtype">{{ $t('xinzeng') }}</el-button>
      </el-form-item>
      <!-- 列表区域 -->
      <el-form-item>
        <el-input
          v-model="queryinfo.query"
          :clearable="true"
          @clear="getlist"
          style="width:240px"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="getlist"
          ></el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" :data="typelist" border style="width: 100%;">
      <el-table-column prop="id" label="#"></el-table-column>
      <el-table-column prop="name" :label="$t('jc.jcfl')"></el-table-column>
      <el-table-column
        prop="action_user"
        :label="$t('jc.cjyh')"
      ></el-table-column>
      <el-table-column
        prop="createtime"
        :label="$t('cjsj')"
        :formatter="dateFormat"
      ></el-table-column>
      <el-table-column :label="$t('caozuo')" min-width="120px">
        <template slot-scope="{ row }">
          <el-tooltip
            :content="$t('bianji')"
            placement="top"
            :enterable="false"
          >
            <i class="iconfont icon-bianji" @click="edit(row.id)"></i>
          </el-tooltip>
          <el-tooltip
            :content="$t('shanchu')"
            placement="top"
            :enterable="false"
          >
            <i class="el-icon-close" @click="del(row.id)"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页区域 -->
    <el-pagination
      :hide-on-single-page="total <= 10"
      background
      layout="prev, pager, next, total"
      :page-size="10"
      :total="total"
      :current-page="queryinfo.page"
      @current-change="changepage"
    ></el-pagination>
    <!-- 添加产品分类弹窗区域 -->
    <el-dialog
      :title="$t('xinzeng')"
      :visible="dialogvisible"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="typeform"
        label-width="180px"
        ref="typeform"
        :rules="formrules"
      >
        <el-form-item :label="$t('jc.jcfl')" prop="name">
          <el-input v-model="typeform.name" :placeholder="$t('qsr')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.jcfl') + '(繁体)'" prop="name">
          <el-input v-model="typeform.name_hk" :placeholder="$t('qsr')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.jcfl') + '(英语)'" prop="name">
          <el-input v-model="typeform.name_en" :placeholder="$t('qsr')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.jcfl') + '(西班牙)'" prop="name">
          <el-input v-model="typeform.name_spa" :placeholder="$t('qsr')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.jcfl') + '(法国)'" prop="name">
          <el-input v-model="typeform.name_fra" :placeholder="$t('qsr')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.jcfl') + '(日本)'" prop="name">
          <el-input v-model="typeform.name_jp" :placeholder="$t('qsr')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.jcfl') + '(韩语)'" prop="name">
          <el-input v-model="typeform.name_kor" :placeholder="$t('qsr')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.jcfl') + '(泰语)'" prop="name">
          <el-input v-model="typeform.name_th" :placeholder="$t('qsr')"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit">{{ $t('tijiao') }}</el-button>
        <el-button @click="handleClose">{{ $t('quxiao') }}</el-button>
      </div>
    </el-dialog>
    <!-- 编辑产品分类弹窗区域 -->
    <el-dialog
      title="编辑"
      :visible="editdialogvisible"
      :before-close="editclose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="editform"
        label-width="180px"
        ref="editform"
        :rules="formrules"
      >
        <el-form-item :label="$t('jc.jcfl')" prop="name">
          <el-input v-model="editform.name"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.jcfl') + '(繁体)'" prop="name">
          <el-input v-model="editform.name_hk"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.jcfl') + '(英语)'" prop="name">
          <el-input v-model="editform.name_en"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.jcfl') + '(西班牙)'" prop="name">
          <el-input v-model="editform.name_spa"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.jcfl') + '(法语)'" prop="name">
          <el-input v-model="editform.name_fra"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.jcfl') + '(韩语)'" prop="name">
          <el-input v-model="editform.name_kor"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.jcfl') + '(日语)'" prop="name">
          <el-input v-model="editform.name_jp"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.jcfl') + '(泰语)'" prop="name">
          <el-input v-model="editform.name_th"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editclose">{{ $t('quxiao') }}</el-button>
        <el-button type="primary" @click="editsubmit">{{
          $t('tijiao')
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 分页请求数据对象
      queryinfo: {
        query: '',
        page: 1
      },
      typelist: [],
      total: 0,
      loading: true,
      // 添加分类数据对象
      typeform: {
        name: '',
        name_en: '',
        name_kor: '',
        name_fra: '',
        name_spa: '',
        name_jp: '',
        name_hk: '',
        name_th: '',
      },
      dialogvisible: false,
      formrules: {
        name: [{ required: true, message: '请填写教程分类', trigger: 'blur' }]
      },
      // 编辑分类数据对象
      editform: {
        name: '',
        name_en: '',
        name_kor: '',
        name_fra: '',
        name_spa: '',
        name_jp: '',
        name_hk: '',
        name_th: '',
        id: ''
      },
      editdialogvisible: false
    }
  },
  created() {
    this.getlist()
  },
  methods: {
    async getlist() {
      const { data } = await this.$http.get('/admin/help/helptype', {
        params: this.queryinfo
      })
      this.typelist = data.data.list
      this.loading = false
    },
    // 添加分类弹窗
    addtype() {
      this.dialogvisible = true
    },
    // 关闭添加弹窗
    handleClose() {
      this.typeform = ''
      this.$refs.typeform.resetFields()
      this.dialogvisible = false
    },
    // 分页事件
    changepage(val) {
      this.queryinfo.page = val
      this.getlist()
    },
    // 格式化时间
    dateFormat(row) {
      const time = row.createtime
      const dt = new Date(parseInt(time) * 1000)
      const y = dt.getFullYear()
      const m = (dt.getMonth() + 1 + '').padStart(2, '0')
      const d = (dt.getDate() + '').padStart(2, '0')
      const hh = (dt.getHours() + '').padStart(2, '0')
      const mm = (dt.getMinutes() + '').padStart(2, '0')
      const ss = (dt.getSeconds() + '').padStart(2, '0')
      return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
    },
    // 提交添加分类
    submit() {
      this.$refs.typeform.validate(async valid => {
        if (!valid) return false
        const { data } = await this.$http.post(
          '/admin/help/addtype',
          this.typeform
        )
        if (data) {
          if (data.code === 200) {
            this.getlist()
            this.$message.success(this.getlang(data.msg))
            this.handleClose()
            location.reload()
          } else {
            this.$message.error(this.getlang(data.msg))
          }
        }
      })
    },
    // 打开编辑弹窗
    async edit(id) {
      this.editdialogvisible = true
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-dialog__body')
      })
      const { data } = await this.$http.get('/admin/help/edittype/id/' + id)
      this.editform.name = data.data.name
      this.editform.name_kor = data.data.name_kor
      this.editform.name_spa = data.data.name_spa
      this.editform.name_fra = data.data.name_fra
      this.editform.name_jp = data.data.name_jp
      this.editform.name_en = data.data.name_en
      this.editform.name_hk = data.data.name_hk
      this.editform.name_th = data.data.name_th
      this.editform.id = data.data.id
      loading.close()
    },
    // 编辑提交
    editsubmit() {
      this.$refs.editform.validate(async valid => {
        if (!valid) return false
        const { data } = await this.$http.post(
          '/admin/help/edittype',
          this.editform
        )
        if (data) {
          if (data.code === 200) {
            this.getlist()
            this.$message.success(this.getlang(data.msg))
            this.editclose()
            location.reload()
          } else {
            this.$message.error(this.getlang(data.msg))
          }
        }
        this.editdialogvisible = false
      })
    },
    // 关闭编辑弹窗
    editclose() {
      this.$refs.editform.resetFields()
      this.editdialogvisible = false
    },
    // 删除操作
    del(id) {
      this.$confirm(this.$t('shanchutips'), this.$t('tishi'), {
        confirmButtonText: '确定',
        cancelButtonText: this.$t('quxiao'),
        type: 'warning'
      }).then(async () => {
        const { data } = await this.$http.post('/admin/help/deltype', {
          id
        })

        if (data) {
          if (data.code === 200) {
            this.getlist()
            this.$message.success(this.getlang(data.msg))
          } else {
            this.$message.error(this.getlang(data.msg))
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.iconfont,
.el-icon-close {
  font-size: 18px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 15px;
  padding: 1px;
  &:hover {
    color: #409eff;
    cursor: pointer;
    border-color: #409eff;
  }
}
</style>
